import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import ModulePermissionForm from "./ModulePermissionForm";
import { Paginator } from "primereact/paginator";

const ModulePermission = () => {
  // use states
  const [permissionData, setPermissionData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [edittable, setedittable] = useState(false); //For edit form opening
  const [editdata, seteditdata] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null); //For getting data in edit form
  const [expandedRows, setExpandedRows] = useState(null);

  // use effect
  useEffect(() => {
    getAllPermission();
  }, []);
  // use effect ended

  const editfunc = (rowData) => {
    setDisplayPosition(true);
    setPosition("center");
    setedittable(true);
    seteditdata(rowData);
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);
  };

  // Getting customer data
  const getAllPermission = async () => {
    setLoading(true);
    const response = await axios.get(`${process.env.REACT_APP_Url}/api/user`);
    if (response.status === 200) {
      // const { data } = response;

      setPermissionData(response.data);
      setLoading(false);
    } else {
      console.log("false");
      setLoading(false);
    }
  };

  // const deleteUserData = (rowData) => {
  //     setSelectedRowToDelete(rowData);
  //     setDeleteConfirmationVisible(true);
  // };
  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button
        label="Cancel"
        className="p-button-secondary"
        onClick={() => setDeleteConfirmationVisible(false)}
      />
      <Button
        label="Delete"
        className="p-button-danger"
        // onClick={confirmDelete}
      />
    </div>
  );

  const allowExpansion = (rowData) => {
    return <>{rowData?.length > 0}</>;
  };
  const nameBodyTemp = (rowData) => {
    const capital = rowData?.username?.toLowerCase();
    return <div>{capital}</div>;
  };
  const emailBodyTemp = (rowData) => {
    const capital = rowData?.email?.toLowerCase();
    return <div>{capital}</div>;
  };

  const permissinAssignment = (rowData) => {
    setDisplayPosition(true);
    setPosition("center");
    setSelectedRow(rowData);
  };
  const assignPermission = (rowData) => {
    return (
      <div>
        <Button
          label="Assign Module "
          body={assignPermission}
          onClick={() => permissinAssignment(rowData)}
          className="p-button-primary mr-3"
        />
      </div>
    );
  };
  const subModuleNameBodyTemp = (rowData) => {
    const capital =
      rowData?.module?.moduleName?.charAt(0)?.toUpperCase() +
      rowData?.module?.moduleName?.slice(1)?.toLowerCase();
    return <div>{capital}</div>;
  };

  const subModuleIconBodyTemp = (rowData) => {
    const capital = rowData?.permissions?.map((p) => {
      return (
        p.permissionName?.charAt(0)?.toUpperCase() +
        p.permissionName?.slice(1)?.toLowerCase()
      );
    });

    return (
      <div>
        {capital.includes("Edit") && (
          <button className="pi pi-pencil ml-2 p-mr-2 edit-icon-background"></button>
        )}
        {capital.includes("Delete") && (
          <button className="pi pi-trash ml-2 p-ml-2 delete-icon-background"></button>
        )}
        {capital.includes("View") && (
          <button
            className="pi pi-eye edit ml-2 p-ml-2 p-button-rounded edit-icon-background"
            tooltip="user detail"
          />
        )}
        {capital.includes("Add") && (
          <button
            icon="pi pi-plus"
            type="button"
            className=" pi pi-plus mr-3  ml-2 p-ml-2 p-button-rounded delete-icon-background"
          />
        )}
      </div>
    );
  };

  const expandedTableTemp = (rowData) => {
    return (
      <DataTable
        showGridlines={true}
        value={rowData.userModPermission?.modules}
      >
        <Column
          style={{ minWidth: "22rem" }}
          field="name"
          body={subModuleNameBodyTemp}
          header="Module Name"
        />
        <Column
          style={{ minWidth: "22rem" }}
          body={subModuleIconBodyTemp}
          header="Permissions"
        />
        {/* <Column body={subModuleStatusBodyTemplate} header="Status" />
                <Column body={subModuleActionTemp} header="Action" /> */}
      </DataTable>
    );
  };
  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>

      {/* code for add new customer button  */}
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2"> Modules Permissions </h3>
            </div>
          </div>

          <Dialog
            header={edittable ? "Edit Supplier Details " : "Module Permission"}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <ModulePermissionForm
              geteditdata={editdata}
              editable={edittable}
              onHide={onHide}
              getAllPermission={getAllPermission}
              selectedRow={selectedRow}
            />
          </Dialog>
        </div>
      </div>
      {/* add new button ended  */}

      {/* table of customers  */}
      <div className="card">
        <DataTable
          filter
          expandedRows={expandedRows}
          rows={5}
          loading={loading}
          value={permissionData}
          responsiveLayout="scroll"
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          paginator
          emptyMessage="No record found."
          rowExpansionTemplate={expandedTableTemp}
        >
          <Column expander={allowExpansion} style={{ width: "3em" }} />

          <Column
            style={{ minWidth: "22rem" }}
            field="name"
            body={nameBodyTemp}
            header="Name"
          ></Column>
          <Column
            style={{ minWidth: "22rem" }}
            field="email"
            body={emailBodyTemp}
            header="Email"
          ></Column>
          <Column className="ml-4" header="" body={assignPermission} />
        </DataTable>
      </div>
    </>
  );
};

export default ModulePermission;
