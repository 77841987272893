import React, { useRef } from "react";
import { Button } from "primereact/button";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

const Invoice = ({ selectedRow, title }) => {
  const pdfFile = useRef();
  const PDF_FILE_URL = pdfFile;
  const handleDownload = (url) => {
    const capture = document.querySelector(".invoice");

    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      doc.save("invoice.pdf");
    });
  };

  return (
    <div>
      {" "}
      <div className="martop dialog-demo mr-5 ml-6">
        <div>
          <Button
            label="Download "
            // icon="pi pi-arrow-left"
            onClick={() => handleDownload(PDF_FILE_URL)}
            className="p-button-primary mr-3 mb-5 "
          />
          <div className="p-grid p-dir-col">
            {/* <div className="p-col addnewbutton"> */}
            <div className="flex flex-end">{/* </div> */}</div>
          </div>
        </div>
      </div>
      {/* add new button ended  */}
      <div
        className="invoice"
        style={{
          paddingLeft: "50px",
          paddingRight: "50px",
          paddingBottom: "20px",
        }}
      >
        <h4>
          {title === "client"
            ? "Client"
            : title === "supplier"
            ? "Supplier"
            : ""}{" "}
          Invoice
        </h4>

        {/*  */}
        <div className="card">
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>
                {title === "client"
                  ? "Client"
                  : title === "supplier"
                  ? "Supplier"
                  : "Customer"}{" "}
              </div>
              <div>
                {title === "client"
                  ? selectedRow.client.Clientname
                  : title === "supplier"
                  ? selectedRow.supplier.supplierName
                  : selectedRow.customer.customerName}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Total Bill </div>
              <div>{selectedRow.totalBill}</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Amount To Be Paid (PKR) </div>
              <div>{selectedRow.amountToBePaid}</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Discount %</div>
              <div>{selectedRow.discount}</div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Product </div>
              <div>
                {" "}
                {selectedRow?.products?.map((p) => {
                  return (
                    <div className="flex">
                      <div>{p?.productId?.products_name}</div>{" "}
                      <div className="ml-2"> {p?.productQuantity} Items</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Created at</div>
              <div>{moment(selectedRow.createdAt).format("YYYY-MM-DD")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
