import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

const ClientFormOrder = ({ onHide, getAllClientsOrders }) => {
  const [productOption, setProductOption] = useState([]);
  const [clientOption, setClientOption] = useState([]);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  useEffect(() => {
    // Make an API request to fetch company data
    axios
      .get(`${process.env.REACT_APP_Url}/api/product/`)
      .then((response) => {
        const products = response.data;

        const options = products?.map((product) => ({
          name: product.products_name, // Displayed text in the dropdown
          code: product._id, // Value associated with the option
        }));

        setProductOption(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  useEffect(() => {
    // Make an API request to fetch company data
    axios
      .get(`${process.env.REACT_APP_Url}/api/client`)
      .then((response) => {
        const client = response.data;

        const options = client.map((c) => ({
          label: c.Clientname, // Displayed text in the dropdown
          value: c._id, // Value associated with the option
        }));

        setClientOption(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    client: Yup.string().required("This field is required."),
    discount: Yup.number("should be a number")
      .max(100, "Maximum length 100 allowed")
      .required("This field is required."),
    productId: Yup.string(),
    productQuantity: Yup.number("Should be a number").max(
      5,
      "Maximum length 5 allowed"
    ),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      client: "",

      discount: "",

      products: [
        {
          productId: "",
          productQuantity: 0,
        },
      ],
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      for (let i = 0; i < values?.products.length; i++) {
        const product = values.products[i];

        product.productQuantity = parseInt(product.productQuantity);
      }
      const obj = {
        client: values.client,
        discount: parseInt(values.discount),

        products: values.products,
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_Url}/api/clientOrder`,
          obj
        );

        if (res.status === 200 || res.status === 201) {
          toast.success("success");
          await getAllClientsOrders();
          onHide();
        } else {
          console.log("false");
        }
      } catch (error) {
        toast.error(error.response.data.error);
      } finally {
        setSaveBtnLoading(false);
      }
      onHide();
      getAllClientsOrders();
    },
  });

  const handleNewProduct = () => {
    formik?.setFieldValue("products", [
      ...formik.values?.products,

      {
        productId: "",
        productQuantity: 0,
      },
    ]);
  };
  const removeProduct = (index) => {
    if (index > 0) {
      const updatedData = [...formik.values.products];

      updatedData.splice(index, 1);

      formik.setFieldValue("products", updatedData);
    }
  };

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("client") })}
            >
              Client{" "}
            </label>

            <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
              <Dropdown
                id="client"
                placeholder="Select a client"
                name="client"
                value={formik.values.client}
                options={clientOption} // Use the options fetched from the API
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("client") },
                  ""
                )}
              />
              {getFormErrorMessage("client")}
            </div>
          </div>
        </div>

        {formik?.values?.products?.map((id, index) => (
          <div className="" key={`id-${index}`}>
            <div
              className="p-fluid p-form grid p-grid grid row"
              style={{ overflow: "hidden" }}
            >
              <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-10">
                {" "}
                <label style={{ fontWeight: "bold" }}>Products </label>
              </div>
              <div className="cataField">
                <Dropdown
                  id={`productId${index}`}
                  name={`products[${index}].productId`}
                  value={formik.values.products[index].productId}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  className={classNames(
                    { "p-invalid": isFormFieldValid("name") },
                    "TextArea__Round"
                  )}
                  options={productOption}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="--Select--"
                ></Dropdown>
                {getFormErrorMessage("productId")}
              </div>
            </div>

            <div
              className="p-fluid p-form grid p-grid grid row"
              style={{ overflow: "hidden" }}
            >
              <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-10">
                <label style={{ fontWeight: "bold" }}>Product Quantity </label>
              </div>
              <div className="cataField">
                <InputText
                  id={`productQuantity${index}`}
                  name={`products[${index}].productQuantity`}
                  value={formik.values.productQuantity}
                  onChange={formik.handleChange}
                  className={classNames(
                    { "p-invalid": isFormFieldValid("productQuantity") },
                    "TextArea__Round"
                  )}
                  type="text"
                />
                {getFormErrorMessage("productQuantity")}
              </div>
            </div>

            <div className="field col-12 md:col-12">
              <Button
                icon="pi pi-plus"
                type="button"
                className="Plus__button mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewProduct();
                }}
              ></Button>
              <Button
                icon="pi pi-minus"
                type="button"
                className="Plus__button "
                onClick={(e) => {
                  e.preventDefault();

                  removeProduct(index);
                }}
              ></Button>
            </div>
          </div>
        ))}
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("discount"),
              })}
            >
              Discount %
            </label>
            <InputText
              id="discount"
              type="discount"
              name="discount"
              value={formik.values.discount}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("discount") },
                ""
              )}
            />
            {getFormErrorMessage("discount")}
          </div>
        </div>

        {/* <div className="p-fluid p-formgrid p-grid grid row mt-3">
          <div className="p-col-12 col-8"></div>
          <div className="p-col-12 col-4">
            <div className="card"></div>
          </div>
        </div> */}

        <div className="flex cus-buton">
          <Button
            label="Submit"
            className="p-button-rounded p-button-primary"
            type="submit"
            loading={saveBtnLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ClientFormOrder;
