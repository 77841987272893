import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import CustomerForm from "../Customer/CustForm";
import { Dialog } from "primereact/dialog";
import Axios from "axios";

export default function InventoryForm({
  geteditdata,
  editable,
  getAllProducts,
  onHide,
}) {
  const [supplierOption, setSupplierOptions] = useState([]);
  const [customerOption, setCustomerOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [adImage, setAdImage] = useState(null);
  const [addAttacment, setAddAttachment] = useState(null);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [customerPosition, setPositionCustomer] = useState(false);

  const validationSchema = Yup.object().shape({
    products_name: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "Invalid name")
      .required("This field is required."),
    local_name: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "Invalid name")
      .required("This field is required."),
    chemical_formulation: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    manufacturer: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    country_of_origin: Yup.string().required("This field is required."),
    SKU: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    packing: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    net_weight: Yup.number()
      .typeError("Value must be a number")
      .required("This field is required."),

    physical_attributes: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    Chemical_attributes: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),
    supplierId: Yup.string(),
    Unit_price: Yup.number("should be a number")
      .typeError("Value must be a number")
      .required("This field is required."),
    Pack_price: Yup.number("should be a number").typeError(
      "Value must be a number"
    ),
    Date: Yup.string().required("This field is required."),
    Industry: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .required("This field is required."),

    // product_quantity: Yup.number("should be a number")
    //   .typeError("Value must be a number")
    //   .required("This field is required"),
    supplier: Yup.array().of(
      Yup.object().shape({
        supplierId: Yup.string().required("Supplier is required"),
        supplierPrice: Yup.number()
          .typeError("Supplier Price must be a number")
          .required("Supplier Price is required")
          .min(0, "Supplier Price must be a positive number"), // Add other validation rules as needed
      })
    ),
  });
  const isFormFieldValid = (name) =>
    !!(formik?.touched[name] && formik?.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    const sup = geteditdata?.supplier;
    for (let i = 0; i < sup?.length; i++) {
      sup[i].Date = moment(sup[i]?.Date).format("YYYY-MM-DD");
      sup[i].supplierDate = sup[i]?.Date;
      delete sup[i].Date;
      if (sup[i]?.supplierId?._id) {
        sup[i].supplierId = sup[i]?.supplierId?._id;
      }
    }
    const cus = geteditdata?.customer;
    for (let i = 0; i < cus?.length; i++) {
      if (cus[i]?.customerId?._id) {
        cus[i].customerId = cus[i]?.customerId?._id;
      }
    }
    if (editable === true) {
      formik.setFieldValue("products_name", geteditdata?.products_name);
      formik.setFieldValue("local_name", geteditdata?.local_name);
      formik.setFieldValue(
        "chemical_formulation",
        geteditdata?.chemical_formulation
      );
      formik.setFieldValue("manufacturer", geteditdata?.manufacturer);

      formik.setFieldValue("country_of_origin", geteditdata?.country_of_origin);
      formik.setFieldValue("SKU", geteditdata?.SKU);

      formik.setFieldValue("packing", geteditdata?.packing);
      formik.setFieldValue("net_weight", geteditdata?.net_weight);
      formik.setFieldValue(
        "physical_attributes",
        geteditdata?.physical_attributes
      );

      formik.setFieldValue(
        "Chemical_attributes",
        geteditdata?.Chemical_attributes
      );
      formik.setFieldValue("product_quantity", geteditdata?.productQuantity);
      formik.setFieldValue("Unit_price", geteditdata?.Unit_price);
      formik.setFieldValue("Pack_price", geteditdata?.Pack_price);
      formik.setFieldValue(
        "Date",
        moment(geteditdata?.Date).format("YYYY-MM-DD")
      );
      formik.setFieldValue("Industry", geteditdata?.Industry);

      formik.setFieldValue("supplier", geteditdata?.supplier);
      formik.setFieldValue("customer", geteditdata?.customer);
    }
  }, [editable]);

  async function readFileAsDataURL(file) {
    if (file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader?.readAsDataURL(file);
      });
      return result_base64;
    }
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      products_name: "",
      local_name: "",
      chemical_formulation: "",
      manufacturer: "",
      country_of_origin: "",
      SKU: "",
      packing: "",
      net_weight: "",
      physical_attributes: "",
      Chemical_attributes: "",
      supplier: [
        {
          supplierId: "",

          supplierPrice: 0,

          supplierDate: "",
        },
      ],
      customer: [{ customerId: "" }],
      Unit_price: "",
      Pack_price: "",
      Date: "",
      Industry: "",
      // company: "",
      product_quantity: "",
    },
    validate: (data) => {},
    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      for (let i = 0; i < values?.supplier?.length; i++) {
        const supplier = values.supplier[i];
        supplier.supplierPrice = parseInt(supplier.supplierPrice);

        supplier.Date = supplier.supplierDate;
        delete supplier.supplierDate;
      }
      if (editable === true) {
        const obj = {
          products_name: formik.values.products_name,
          local_name: formik.values.local_name,
          chemical_formulation: formik.values.chemical_formulation,
          manufacturer: formik.values.manufacturer,

          country_of_origin: formik.values.country_of_origin,
          SKU: formik.values.SKU,
          packing: formik.values.packing,
          net_weight: formik.values.net_weight,
          physical_attributes: formik.values.physical_attributes,
          Chemical_attributes: formik.values.Chemical_attributes,
          supplier: values.supplier,
          customer: values.customer,
          Unit_price: formik.values.Unit_price,
          Pack_price: formik.values.Pack_price,
          Date: formik.values.Date,
          Industry: formik.values.Industry,
          // company: formik.values.company,
          productQuantity: formik.values.product_quantity,
        };

        /// add image

        let imageBase64 = await readFileAsDataURL(adImage);

        const data = {
          _id: geteditdata?._id,
          image: imageBase64,
        };
        try {
          await Axios.patch(
            `${process.env.REACT_APP_Url}/api/product/uploadProductImage`,
            data
          );
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }

        /// add attachment

        let attachmentBase64 = await readFileAsDataURL(addAttacment);

        const data2 = {
          _id: geteditdata?._id,
          file: attachmentBase64,
        };
        try {
          const res = await Axios.patch(
            `${process.env.REACT_APP_Url}/api/product/uploadProductFile`,
            data2
          );
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }

        try {
          const res = await Axios.put(
            `${process.env.REACT_APP_Url}/api/product/${geteditdata?._id}`,
            obj
          );

          if (res.status === 200) {
            toast.success("success");
            await getAllProducts();
            onHide();
          } else {
            console.log("error");
          }
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllProducts();
      } else {
        let attachmentBase64 = await readFileAsDataURL(addAttacment);
        let imageBase64 = await readFileAsDataURL(adImage);
        for (let i = 0; i < values?.supplier?.length; i++) {
          const supplier = values.supplier[i];
          supplier.supplierPrice = parseInt(supplier.supplierPrice);

          supplier.Date = supplier.supplierDate;
          delete supplier.supplierDate;
        }

        const obj = {
          productData: {
            products_name: formik.values.products_name,
            local_name: formik.values.local_name,
            chemical_formulation: formik.values.chemical_formulation,
            manufacturer: formik.values.manufacturer,
            country_of_origin: formik.values.country_of_origin,
            SKU: formik.values.SKU,
            packing: formik.values.packing,
            net_weight: formik.values.net_weight,
            physical_attributes: formik.values.physical_attributes,
            Chemical_attributes: formik.values.Chemical_attributes,
            supplier: values.supplier,
            customer: values.customer,
            Unit_price: formik.values.Unit_price,
            Pack_price: formik.values.Pack_price,
            Date: formik.values.Date,
            Industry: formik.values.Industry,
            // company: formik.values.company,
            productQuantity: formik.values.product_quantity,
          },
          ProductImage: imageBase64,

          ProductFile: attachmentBase64,
        };

        try {
          const res = await Axios.post(
            `${process.env.REACT_APP_Url}/api/product`,
            obj
          );

          if (res.status === 200 || res.status === 201) {
            toast.success("success");
            await getAllProducts();
            onHide();
          } else {
            console.log("error");
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.response?.data?.message);
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllProducts();
      }
    },
  });

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_Url}/api/supplier/getAll`)
      .then((response) => {
        const suppliers = response.data.data;

        const options = suppliers.map((supplier) => ({
          label: supplier.supplierName, // Displayed text in the dropdown
          value: supplier._id, // Value associated with the option
        }));

        setSupplierOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_Url}/api/customer/getAll`)
      .then((response) => {
        const customer = response.data.data;

        const options = customer.map((cus) => ({
          label: cus.customerName, // Displayed text in the dropdown
          value: cus._id, // Value associated with the option
        }));

        setCustomerOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  ///countries
  useEffect(() => {
    Axios.get(
      `https://officeautomation-node.appinsnap.com/api/lovList/listOfCountries`
    )
      .then((response) => {
        const contries = response.data;

        const options = contries.map((country) => ({
          label: country.country_name,
          value: country.country_name,
        }));

        setCountryOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  const handleNewSupplier = () => {
    formik?.setFieldValue("supplier", [
      ...formik.values?.supplier,

      {
        supplierId: "",

        supplierPrice: 0,

        supplierDate: "",
      },
    ]);
  };
  const handleNewCustomer = () => {
    formik.setFieldValue("customer", [
      ...formik.values.customer,
      {
        customerId: "",
      },
    ]);
  };
  const removeSupplier = (index) => {
    if (index > 0) {
      const updatedData = [...formik.values.supplier];

      updatedData.splice(index, 1);

      formik.setFieldValue("supplier", updatedData);
    }
  };
  const removeCustomer = (index) => {
    if (index > 0) {
      const updatedData = [...formik.values.customer];
      updatedData.splice(index, 1);
      formik.setFieldValue("customer", updatedData);
    }
  };
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };
  const onHide1 = (name) => {
    setDisplayPosition(false);
    setPositionCustomer(false);
  };
  return (
    <div className="card">
      <div className="p-fluid p-formgrid p-grid grid row"></div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          header={"Add Customer  "}
          visible={displayPosition}
          position={customerPosition}
          modal
          style={{ width: "65vw" }}
          onHide={() => onHide1()}
          draggable={false}
          resizable={false}
        >
          <CustomerForm onHide={onHide1} />
        </Dialog>
        <div className="p-fluid p-formgrid p-grid grid row">
          <>
            <div className="p-field col-xs-12 col-sm-6 col-md-6  col-lg-6">
              <label
                className={classNames({
                  "p-error": isFormFieldValid("imageUrl"),
                })}
              >
                Product Image
              </label>
              <div className="flex">
                {" "}
                {editable && (
                  <img
                    style={{
                      width: "60px",
                      marginRight: "10px",
                      borderRadius: "100%",
                    }}
                    src={
                      process.env.REACT_APP_Url +
                      "/" +
                      geteditdata?.ProductImage
                    }
                    alt=""
                  />
                )}
                <InputText
                  id="imageUrl"
                  name="imageUrl"
                  label="Image"
                  value={formik.values.adImage}
                  formik={formik}
                  onChange={(e) => {
                    setAdImage(e.target.files[0]);
                  }}
                  type="file"
                />
                {getFormErrorMessage("imageUrl")}
              </div>
            </div>

            <div className="p-field col-xs-12 col-sm-6 col-md-6  col-lg-6">
              <label
                className={classNames({
                  "p-error": isFormFieldValid("addAttachment"),
                })}
              >
                Add attachment
              </label>
              <div className="flex align-items-center ">
                {editable && (
                  <a
                    className="w-4 mr-2 text-green-700"
                    target="_blank"
                    rel="noreferrer"
                    href={process.env.REACT_APP_Url + "/" + geteditdata?.file}
                  >
                    {geteditdata?.file ? "Open attachment" : "No file"}
                  </a>
                )}

                <InputText
                  id="addAttachment"
                  name="addAttachment"
                  label="Attachment"
                  value={formik.values.addAttachment}
                  formik={formik}
                  onChange={(e) => {
                    setAddAttachment(e.target.files[0]);
                  }}
                  type="file"
                />
                {getFormErrorMessage("addAttachment")}
              </div>
            </div>
          </>

          <div className="p-field col-xs-12 col-sm-6 col-md-6  col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("products_name"),
              })}
            >
              Product Name*
            </label>

            <InputText
              id="products_name"
              name="products_name"
              value={formik.values.products_name}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("products_name") },
                ""
              )}
            />
            {getFormErrorMessage("products_name")}
          </div>

          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("local_name"),
              })}
            >
              Local Name*
            </label>
            <InputText
              id="local_name"
              name="local_name"
              value={formik.values.local_name}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("local_name") },
                ""
              )}
            />
            {getFormErrorMessage("local_name")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              htmlFor="chemical_formulation"
              className={classNames({
                "p-error": isFormFieldValid("chemical_formulation"),
              })}
            >
              Chemical Formula*
            </label>
            <InputText
              id="chemical_formulation"
              type="text"
              name="chemical_formulation"
              value={formik.values.chemical_formulation}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("chemical_formulation") },
                ""
              )}
            />
            {getFormErrorMessage("chemical_formulation")}
          </div>
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("manufacturer"),
              })}
            >
              Manufacturer*
            </label>
            <InputText
              id="manufacturer"
              type="text"
              name="manufacturer"
              value={formik.values.manufacturer}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("manufacturer") },
                ""
              )}
            />
            {getFormErrorMessage("manufacturer")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row"></div>
        {formik?.values?.supplier?.map((id, index) => (
          <div
            className="p-fluid p-formgrid p-grid grid row "
            key={`id-${index}`}
          >
            <div className="p-field col-xs-12 col-sm-12 col-md-6  col-lg-6">
              {" "}
              <label>Suppliers* </label>
              <div className="cataField">
                <Dropdown
                  id={`supplierId${index}`}
                  name={`supplier[${index}].supplierId`}
                  value={formik.values.supplier[index].supplierId}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  className={classNames(
                    { "p-invalid": isFormFieldValid("name") },
                    "TextArea__Round"
                  )}
                  options={supplierOption}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="--Select--"
                ></Dropdown>
                {getFormErrorMessage("supplierId")}

                <p
                  style={{
                    color: "#e24c4c",
                    fontSize: ".875em",
                    marginLeft: "8px",
                  }}
                >
                  {formik.errors?.supplier?.[index]?.supplierId}
                </p>
              </div>
            </div>

            <div className="p-field col-xs-12 col-sm-12 col-md-6  col-lg-6">
              <label>Supplier Price*</label>

              <InputText
                id={`supplierPrice${index}`}
                name={`supplier[${index}].supplierPrice`}
                value={formik.values.supplier[index].supplierPrice}
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("supplierPrice") },
                  "TextArea__Round"
                )}
                type="text"
              />

              <p
                style={{
                  color: "#e24c4c",
                  fontSize: ".875em",
                  marginLeft: "8px",
                }}
              >
                {formik.errors?.supplier?.[index]?.supplierPrice}
              </p>
            </div>

            <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label
                className={classNames({
                  "p-error": isFormFieldValid("supplierDate"),
                })}
              >
                {" "}
                Date*
              </label>
              <InputText
                id={`supplierDate${index}`}
                type="date"
                name={`supplier[${index}].supplierDate`}
                value={formik.values.supplier[index].supplierDate}
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("supplierDate") },
                  ""
                )}
              />
              {getFormErrorMessage("supplierDate")}
            </div>

            <div className="field col-12 md:col-12">
              <Button
                icon="pi pi-plus"
                type="button"
                className="Plus__button mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewSupplier();
                }}
              ></Button>
              <Button
                icon="pi pi-minus"
                type="button"
                className="Plus__button"
                onClick={(e) => {
                  e.preventDefault();

                  removeSupplier(index);
                }}
              ></Button>
            </div>
          </div>
        ))}

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-4">
            <Button
              label="Add new  customer "
              // icon="pi pi-arrow-left"
              onClick={() => onClick("displayPosition", "center")}
              className="p-button-primary mr-3 "
            />
          </div>
        </div>

        {formik.values.customer.map((id, index) => (
          <div
            className="p-fluid p-formgrid p-grid grid row "
            key={`id-${index}`}
          >
            <div className="p-field col-xs-12 col-sm-12 col-md-6  col-lg-6">
              {" "}
              <label>Customers* </label>
              <div className="cataField">
                <Dropdown
                  id={`customerId${index}`}
                  name={`customer[${index}].customerId`}
                  value={formik.values.customer[index].customerId}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  className={classNames(
                    { "p-invalid": isFormFieldValid("name") },
                    "TextArea__Round"
                  )}
                  options={customerOption}
                  optionLabel="label"
                  optionValue="value"
                  placeholder="--Select--"
                ></Dropdown>
                {getFormErrorMessage("customerId")}

                <p
                  style={{
                    color: "#e24c4c",
                    fontSize: ".875em",
                    marginLeft: "8px",
                  }}
                >
                  {formik.errors?.customer?.[index]?.customerId}
                </p>
              </div>
            </div>

            <div className="field col-12 md:col-12">
              <Button
                icon="pi pi-plus"
                type="button"
                className="Plus__button mr-3"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewCustomer();
                }}
              ></Button>
              <Button
                icon="pi pi-minus"
                type="button"
                className="Plus__button "
                onClick={(e) => {
                  e.preventDefault();

                  removeCustomer(index);
                }}
              ></Button>
            </div>
          </div>
        ))}

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({ "p-error": isFormFieldValid("SKU") })}
            >
              {" "}
              SKU*
            </label>
            <InputText
              id="SKU"
              type="text"
              name="SKU"
              value={formik.values.SKU}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("SKU") },
                ""
              )}
            />
            {getFormErrorMessage("SKU")}
          </div>
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({ "p-error": isFormFieldValid("packing") })}
            >
              Packing*
            </label>
            <InputText
              id="packing"
              type="text"
              name="packing"
              value={formik.values.packing}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("packing") },
                ""
              )}
            />
            {getFormErrorMessage("packing")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("net_weight"),
              })}
            >
              {" "}
              Net Weight(g)*
            </label>
            <InputText
              id="net_weight"
              type="text"
              name="net_weight"
              value={formik.values.net_weight}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("net_weight") },
                ""
              )}
            />
            {getFormErrorMessage("net_weight")}
          </div>
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("physical_attributes"),
              })}
            >
              Physical Attributes*
            </label>
            <InputText
              id="physical_attributes"
              type="textarea"
              name="physical_attributes"
              value={formik.values.physical_attributes}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("physical_attributes") },
                ""
              )}
            />
            {getFormErrorMessage("physical_attributes")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("country_of_origin"),
              })}
            >
              Origin*
            </label>

            <Dropdown
              id="country_of_origin"
              placeholder="Select a Country"
              name="country_of_origin"
              value={formik.values.country_of_origin || ""}
              options={countryOptions} // Use the options fetched from the API
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("country_of_origin") },
                ""
              )}
            />
            {getFormErrorMessage("country_of_origin")}
          </div>
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("Chemical_attributes"),
              })}
            >
              {" "}
              Chemical Attributes*
            </label>
            <InputText
              id="Chemical_attributes"
              type="textarea"
              name="Chemical_attributes"
              value={formik.values.Chemical_attributes}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("Chemical_attributes") },
                ""
              )}
            />
            {getFormErrorMessage("Chemical_attributes")}
          </div>
          {/* <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("product_quantity"),
              })}
            >
              {" "}
              Product quantity*
            </label>
            <InputText
              id="product_quantity"
              type="textarea"
              name="product_quantity"
              value={formik.values.product_quantity}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("product_quantity") },
                ""
              )}
            />
            {getFormErrorMessage("product_quantity")}
          </div> */}
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("Unit_price"),
              })}
            >
              {" "}
              Unit Price (PKR)*
            </label>
            <InputText
              id="Unit_price"
              type="text"
              name="Unit_price"
              value={formik.values.Unit_price}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("Unit_price") },
                ""
              )}
            />
            {getFormErrorMessage("Unit_price")}
          </div>
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("Pack_price"),
              })}
            >
              {" "}
              Pack Price (PKR)
            </label>
            <InputText
              id="Pack_price"
              type="text"
              name="Pack_price"
              value={formik.values.Pack_price}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("Pack_price") },
                ""
              )}
            />
            {getFormErrorMessage("Pack_price")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({ "p-error": isFormFieldValid("Date") })}
            >
              {" "}
              Date*
            </label>
            <InputText
              id="Date"
              type="date"
              name="Date"
              value={formik.values.Date}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("Date") },
                ""
              )}
            />
            {getFormErrorMessage("Date")}
          </div>

          <div className="p-field col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <label
              className={classNames({
                "p-error": isFormFieldValid("Industry"),
              })}
            >
              Industry*
            </label>
            <InputText
              id="Industry"
              type="text"
              name="Industry"
              value={formik.values.Industry}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("Industry") },
                ""
              )}
            />
            {getFormErrorMessage("Industry")}
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
            autoFocus
          />
        </div>
      </form>
    </div>
  );
}
