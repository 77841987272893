import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import moment from "moment";
import axios from "axios";
const Inquiry = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState(null);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const handleChange = (e, val) => {
    if (val === "start") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const onSubmit = async () => {
    const data = {
      customerName: globalFilterValue,
      startDate: startdate,
      endDate: endDate,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Url}/api/product/Inquiry`,
        data
      );
      if (res.status === 200 || res.status === 201) {
        console.log(res.data.data, "res");
        setData(res.data.data);
      }
    } catch {
      console.log("error");
    } finally {
    }
  };
  return (
    <div className="card">
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Inquiry Management </h3>
              <div className="flex align-items-center">
                <div className="flex justify-content-end">
                  <span className="p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder=" Search"
                    />
                  </span>
                  <div className="p-field col-xs-4 mr-2">
                    <InputText
                      type="date"
                      value={startdate}
                      onChange={(e) => handleChange(e, "start")}
                    />
                  </div>
                  <div className="p-field col-xs-4 mr-2">
                    <InputText
                      type="date"
                      value={endDate}
                      onChange={(e) => handleChange(e, "end")}
                    />
                  </div>
                </div>
                {
                  <Button
                    label="Search "
                    // icon="pi pi-arrow-left"
                    onClick={() => onSubmit("displayPosition", "center")}
                    className="p-button-primary"
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="invoice"
        style={{
          display: "flex",
          justifyContent: "space-between",

          width: "40rem",
        }}
      ></div>
      <div>
        <h4>Details</h4>
        <ol>
          <div style={{ marginBottom: "4px" }}>
            {" "}
            <span
              style={{
                background: "#D3D3D3",
                width: "150px",
                display: "inline-block",
                padding: "4px",
              }}
            >
              Product
            </span>{" "}
            <span
              style={{
                background: "#D3D3D3",
                width: "20rem",
                display: "inline-block",
                padding: "4px",
              }}
            >
              Customer Name
            </span>
            <span
              style={{
                background: "#D3D3D3",
                width: "20rem",
                display: "inline-block",
                padding: "4px",
              }}
            >
              Sales person
            </span>
            <span
              style={{
                background: "#D3D3D3",
                width: "150px",
                display: "inline-block",
                padding: "4px",
              }}
            >
              Date
            </span>
          </div>

          {data?.map((d) => {
            return (
              <>
                {" "}
                <li style={{ marginBottom: "4px" }}>
                  {" "}
                  <span
                    style={{
                      background: "#e5e3e3",
                      width: "150px",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {d?.products_name}
                  </span>{" "}
                  <span
                    style={{
                      background: "#e5e3e3",
                      width: "20rem",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {d?.customer?.map((r) => r.customerId.customerName)}
                  </span>
                  <span
                    style={{
                      background: "#e5e3e3",
                      width: "20rem",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {d?.customer?.map((r) => r?.customerId?.salesPerson)}
                  </span>
                  <span
                    style={{
                      background: "#e5e3e3",
                      width: "150px",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {d?.customer?.map((r) =>
                      moment(r?.BoughtAt).format("YYYY-MM-DD")
                    )}
                  </span>{" "}
                </li>
              </>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Inquiry;
