import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import CustomerForm from "./CustForm";
import { InputText } from "primereact/inputtext";
import CustomerOrder from "./CustomerOrder/CustomerOrder";
import { toast } from "react-toastify";
import { FilterMatchMode } from "primereact/api";
import CustomerDetail from "./CustomerDetail";

const Cust = () => {
  // use states
  const [permission, setPermissions] = useState();
  const [cusData, setCusData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [edittable, setedittable] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  //For edit form opening
  const [editdata, seteditdata] = useState();

  const [expandedRows, setExpandedRows] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null); //For getting data in edit form
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [customerId, setCustomerId] = useState();
  const [detailConfirmationVisible, setDetailConfirmationVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const getAllCustomers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/api/customer/getAll`
      );
      if (response.status === 200) {
        const { data } = response;

        const reversedData = data.data.reverse();

        setCusData(reversedData);
        setLoading(false);
      } else {
        console.log("false");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCustomers();
  }, []);
  // use effect ended

  // custom functions
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const editfunc = (rowData) => {
    setDisplayPosition(true);
    setPosition("center");
    setedittable(true);
    seteditdata(rowData);
  };
  const handleViewDetails = (rowData) => {
    setCustomerId(rowData?._id);
    setDetailConfirmationVisible(true);
  };

  const icon = (rowData) => {
    let mod = JSON.parse(localStorage.getItem("modulesAccess"));
    const f = mod.filter((m) => m?.module?.moduleName === "Customer");
    const permissions = f[0]?.permissions?.map((p) => {
      return p.permissionName;
    });

    return (
      <div>
        {permissions?.includes("Edit") && (
          <button
            className="pi pi-pencil ml-2 p-mr-2 edit-icon-background"
            onClick={() => editfunc(rowData)}
          ></button>
        )}
        {permissions?.includes("Delete") && (
          <button
            className="pi pi-trash ml-2 p-ml-2 delete-icon-background"
            onClick={() => deleteUserData(rowData)}
          ></button>
        )}

        <button
          icon="pi pi-plus"
          type="button"
          onClick={() =>
            onClick(
              "displayPosition",
              "center",
              setEditProduct(true),
              seteditdata(rowData)
            )
          }
          className=" pi pi-plus mr-3  ml-2 p-ml-2 p-button-rounded edit-icon-background"
        />
        {/* {permissions?.includes("View") && (
          <button
            className="pi pi-eye ml-2 p-ml-2 edit-icon-background"
            onClick={() => handleViewDetails(rowData)}
          ></button>
        )} */}
      </div>
    );
  };
  useEffect(() => {
    let mod = JSON.parse(localStorage.getItem("modulesAccess"));
    const f = mod.filter((m) => m?.module?.moduleName === "Customer");
    let permissions = f[0]?.permissions?.map((p) => {
      return p?.permissionName;
    });

    setPermissions(permissions);
  }, [localStorage.getItem("modulesAccess")]);
  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);
  };

  // Getting customer data

  // Getting customer data  ends

  // deleting user data
  // const deleteUserData = async (rowData) => {

  //   if (response.status === 200) {
  //     getAllCustomers();
  //   }
  //   else {
  //     console.log("false")
  //   }
  // };

  const confirmDelete = async () => {
    setSaveBtnLoading(true);
    try {
      if (selectedRowToDelete) {
        const response = await axios.delete(
          `${process.env.REACT_APP_Url}/api/customer/delete/${selectedRowToDelete._id}`
        );
        if (response.status === 200) {
          getAllCustomers();
          toast.success("Deleted Successfully");
        }
      }
      setDeleteConfirmationVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setSaveBtnLoading(false);
    }
  };

  const deleteUserData = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDeleteConfirmationVisible(true);
  };
  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button
        label="Cancel"
        className="p-button-secondary"
        onClick={() => setDeleteConfirmationVisible(false)}
      />
      <Button
        label="Delete"
        className="p-button-danger"
        onClick={confirmDelete}
        loading={saveBtnLoading}
      />
    </div>
  );

  const expandedTableTemp = (rowData) => {
    return (
      <>
        <DataTable
          value={rowData.products}
          loading={loading}
          filterDisplay="row"
          responsiveLayout="scroll"
        >
          <Column body={pName} header="Products " />
        </DataTable>
      </>
    );
  };
  const pName = (rowData) => {
    return <div>{rowData?.productName?.products_name}</div>;
  };
  const allowExpansion = (rowData) => {
    return <>{rowData?.length > 0}</>;
  };

  // custom function ENDED

  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>

      <Dialog
        draggable={false}
        visible={detailConfirmationVisible}
        style={{ width: "70vw" }}
        onHide={() => setDetailConfirmationVisible(false)}
      >
        <CustomerDetail onHide={onHide} customerId={customerId} />
      </Dialog>

      {/* code for add new customer button  */}
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Customer Management </h3>
              <div className="flex align-items-center">
                <div className="flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder=" Search"
                    />
                  </span>
                </div>
                {permission?.includes("Add") && (
                  <Button
                    label="Add new "
                    // icon="pi pi-arrow-left"
                    onClick={() => onClick("displayPosition", "center")}
                    className="p-button-primary mr-3 ml-3"
                  />
                )}
              </div>
            </div>
          </div>

          <Dialog
            header={edittable ? "Edit Customer Details " : "Add Customer  "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <CustomerForm
              geteditdata={editdata}
              editable={edittable}
              editProduct={editProduct}
              onHide={onHide}
              getAllCustomers={getAllCustomers}
            />
          </Dialog>
        </div>
      </div>
      {/* add new button ended  */}

      {/* table of customers  */}
      <div className="card">
        <DataTable
          expandedRows={expandedRows}
          rowExpansionTemplate={expandedTableTemp}
          onRowToggle={(e) => {
            setExpandedRows(e.data);
          }}
          value={cusData}
          loading={loading}
          filters={filters}
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          rows={5}
          paginator
        >
          <Column expander={allowExpansion} style={{ width: "3em" }} />
          <Column field="customerName" header="Customer Name" />
          <Column field="email" header="Email" />
          <Column field="address" header="Address" style={{ width: "20rem" }} />
          <Column field="contact" header="Contact" />

          <Column className="ml-4" header="Action" body={icon} />
        </DataTable>
      </div>
      {/* <CustomerOrder /> */}
      {/* table of customer ended  */}
    </>
  );
};

export default Cust;
