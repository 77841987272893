import React, { useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";

import { InputText } from "primereact/inputtext";

import axios from "axios";
import { toast } from "react-toastify";
import { Password } from "primereact/password";
import * as Yup from "yup";
const ForgetPassword = ({ handleForgotPassword }) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [verified, setVerified] = useState(false);
  const [otp, setOtp] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .max(100, "Maximum length 100 allowed"),
    password: Yup.string().min(6, "Password must be at least 6 characters"),
    reEnterPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: "",
    },

    onSubmit: async (data) => {
      setSaveBtnLoading(true);
      if (verified) {
        const obj = {
          email: formik.values.email,

          password: formik.values.password,
          reEnterPassword: formik.values.reEnterPassword,
        };
        try {
          const res = await axios.patch(
            `${process.env.REACT_APP_Url}/api/user/ForgotPassword`,
            obj
          );
          if (res.status === 200) {
            toast.success(res.data.message);
            setVerified(true);
            setSuccess(false);
            handleForgotPassword();
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
        } finally {
          setSaveBtnLoading(false);
        }
      }
      if (success) {
        const obj = {
          email: formik.values.email,
          otp: formik.values.otp,
        };
        try {
          const res = await axios.patch(
            `${process.env.REACT_APP_Url}/api/user/Verifyotp`,
            obj
          );
          if (res.status === 200) {
            toast.success(res.data.message);
            setVerified(true);
            setSuccess(false);
            setOtp(false);
          }
          if (res.status === 203) {
            toast.warn(res.data);
          }
        } catch (error) {
          toast.error(error?.response?.data?.message);
        } finally {
          setSaveBtnLoading(false);
        }
      }
      if (!success && !verified) {
        const obj = {
          email: formik.values.email,
        };
        try {
          const res = await axios.patch(
            `${process.env.REACT_APP_Url}/api/user/otp`,
            obj
          );
          if (res.status === 200) {
            toast.success(res.data.message);

            setSuccess(true);
            formik.resetForm();
          }
          if (res.status === 203) {
            toast.warn(res.data);
          }
        } catch (error) {
          toast.error(error);
        } finally {
          setSaveBtnLoading(false);
        }
        // setloading(false);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const handleContinue = () => {
    handleForgotPassword();
  };
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        style={{ background: "#91B8F3", display: "flex" }}
      >
        <div className="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
          <div className="flex flex-column align-items-right justify-content-right">
            <div
              style={{
                borderRadius: "56px",
                padding: "0.3rem",
                background:
                  "linear-gradient(180deg, #407772 10%, rgba(33, 150, 243, 0) 30%)",
              }}
            >
              <div
                className="w-full surface-card py-8 px-5 sm:px-8"
                style={{ borderRadius: "53px" }}
              >
                <div className="text-center mb-5">
                  {/* <img src="assets/layout/images/Zindigi.png" alt="Image" height="50" className="mb-3" /> */}
                  <div className=" text-3xl font-medium mb-3">
                    Forgot Password?
                  </div>
                  <span className="text-600 font-medium">
                    Enter email to continue
                  </span>
                </div>

                <div className="login-div">
                  <>
                    {" "}
                    <label
                      htmlFor="email1"
                      className="block  text-xl font-medium mb-2"
                    >
                      Email
                    </label>
                    <InputText
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      type="email"
                      autoComplete="off"
                      className="w-full mb-3"
                    />
                    {getFormErrorMessage("email")}
                  </>
                  {success && (
                    <>
                      {" "}
                      <label
                        htmlFor="otp"
                        className="block  text-xl font-medium mb-2"
                      >
                        Otp
                      </label>
                      <InputText
                        id="otp"
                        name="otp"
                        value={formik.values.otp}
                        onChange={formik.handleChange}
                        type="text"
                        autoComplete="off"
                        className="w-full mb-3"
                      />
                      {getFormErrorMessage("otp")}
                    </>
                  )}
                  {verified && (
                    <>
                      <label
                        htmlFor="password1"
                        className="block  font-medium text-xl mb-2"
                      >
                        Password
                      </label>
                      <Password
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        toggleMask
                        autoComplete="off"
                        feedback={false}
                        className="w-full mb-3"
                      />
                      {getFormErrorMessage("password")}{" "}
                      <label
                        htmlFor="password1"
                        className="block  font-medium text-xl mb-2"
                      >
                        Confirm Password
                      </label>
                      <Password
                        id="reEnterPassword"
                        name="reEnterPassword"
                        value={formik.values.reEnterPassword}
                        onChange={formik.handleChange}
                        toggleMask
                        autoComplete="off"
                        feedback={false}
                        className="w-full mb-3"
                      />
                      {getFormErrorMessage("reEnterPassword")}
                    </>
                  )}
                  <Button
                    label="Submit"
                    loading={saveBtnLoading}
                    type="submit"
                    className="w-full mt-3 p-3 text-xl"
                  />

                  <div
                    className="font-medium no-underline mt-2 cursor-pointer"
                    style={{ color: "var(--primary-color)" }}
                    onClick={handleContinue}
                  >
                    Continue to login
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
