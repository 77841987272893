import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
export default function UserForm({
  geteditdata,
  editable,
  onHide,
  getAllUsers,
}) {
  const [roleOption, setRoleOption] = useState([]);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()

      .max(50, "Maximum length 50 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "invalid name")
      .required("This field is required."),
    email: Yup.string()
      .max(100, "Maximum length 100 allowed")
      .email("Invalid email")
      .required("This field is required."),
    address: Yup.string().max(100, "Maximum length 100 allowed"),

    password:
      editable === true
        ? null
        : Yup.string()
            .required("This field is required.")
            .min(6, "Password must be at least 6 characters"),
    RoleId: Yup.string().required("Required"),
    contact: Yup.string().matches(/^03[0-9]{9}$/, "invalid number"),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (editable === true) {
      formik.setFieldValue("name", geteditdata?.username);
      formik.setFieldValue("address", geteditdata?.address);
      formik.setFieldValue("email", geteditdata?.email);
      formik.setFieldValue("RoleId", geteditdata?.RoleId?._id);
      formik.setFieldValue("contact", geteditdata?.contact);
    }
  }, [editable]);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      address: "",
      email: "",
      password: "",
      RoleId: "",
      contact: "",
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      if (editable === true) {
        const obj = {
          username: formik.values.name,
          email: formik.values.email,
          address: formik.values.address,
          contact: formik.values.contact,
        };

        try {
          const res = await axios.put(
            `${process.env.REACT_APP_Url}/api/user/${geteditdata?._id}`,
            obj
          );

          if (res.status === 200) {
            toast.success("success");
            await getAllUsers();
            onHide();
          } else {
          }
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllUsers();
      } else {
        const obj = {
          username: formik.values.name,
          email: formik.values.email,
          password: formik.values.password,
          address: formik.values.address,
          RoleId: formik.values.RoleId,
          contact: formik.values.contact,
        };

        try {
          const res = await axios.post(
            `${process.env.REACT_APP_Url}/api/user`,
            obj
          );
          if (res.status === 200 || res.status === 201) {
            await getAllUsers();
            onHide();
            toast.success("success");
          } else {
          }
        } catch (error) {
          toast.error(error.response.data.message);
          toast.error(error.response.data.error);
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllUsers();
      }
    },
  });

  useEffect(() => {
    // Make an API request to fetch company data
    axios
      .get(`${process.env.REACT_APP_Url}/api/role/getAll`)
      .then((response) => {
        const role = response?.data?.data;

        const options = role.map((c) => ({
          label: c.name, // Displayed text in the dropdown
          value: c._id, // Value associated with the option
        }));

        setRoleOption(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  return (
    <div className="card">
      <div className="p-fluid p-formgrid p-grid grid row"></div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("RoleId") })}
            >
              Role*
            </label>

            <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
              <Dropdown
                id="RoleId"
                placeholder="Select a role"
                name="RoleId"
                value={formik.values.RoleId || ""}
                options={roleOption} // Use the options fetched from the API
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("RoleId") },
                  ""
                )}
              />
              {getFormErrorMessage("RoleId")}
            </div>
          </div>
        </div>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("name") })}
            >
              User Name*
            </label>

            <InputText
              id="name"
              name="name"
              value={formik.values.name || ""}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("name") },
                ""
              )}
            />
            {getFormErrorMessage("name")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          {editable === true ? null : (
            <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
              <label
                className={classNames({
                  "p-error": isFormFieldValid("password"),
                })}
              >
                Password*
              </label>
              <Password
                id="password"
                type="password"
                name="password"
                toggleMask
                value={formik.values.password || ""}
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("password") },
                  ""
                )}
              />
              {getFormErrorMessage("password")}
            </div>
          )}
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("email") })}
            >
              Email*
            </label>
            <InputText
              id="email"
              type="email"
              name="email"
              value={formik.values.email || ""}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("email") },
                ""
              )}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("address") })}
            >
              Address{" "}
            </label>
            <InputText
              id="address"
              type="address"
              name="address"
              value={formik.values.address || ""}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("address") },
                ""
              )}
            />
            {getFormErrorMessage("address")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("contact") })}
            >
              Contact
            </label>
            <div className="flex">
              {" "}
              {/* <Dropdown
                placeholder="+92"
                name="code"
                value={+92}
                // options={roleOption}
                // onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("RoleId") },
                  ""
                )}
              /> */}
              <InputText
                id="contact"
                type="contact"
                name="contact"
                value={formik.values.contact || ""}
                onChange={formik.handleChange}
                className={classNames(
                  { "p-invalid": isFormFieldValid("contact") },
                  ""
                )}
              />
              {getFormErrorMessage("contact")}
            </div>
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
