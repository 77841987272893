import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../ManageSales.css";
import ClientFormOrder from "./ClientFromOrder";
import Invoice from "../../Invoice/Invoice";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
import { InputText } from "primereact/inputtext";

import { FilterMatchMode } from "primereact/api";
const ClientOrder = () => {
  // use states

  const [clientOrderData, setClientOrderData] = useState();
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [edittable, setedittable] = useState(false); //For edit form opening

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const getAllClientsOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_Url}/api/clientOrder/`
      );
      if (res.status === 200) {
        setClientOrderData(res?.data?.data);
        setLoading(false);
      } else {
        console.log("false");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllClientsOrders();
  }, []);
  // use effect ended

  // custom functions
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);
  };

  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button
        label="Cancel"
        className="p-button-secondary"
        onClick={() => setDeleteConfirmationVisible(false)}
      />
    </div>
  );

  const product = (rowData) => {
    const pro = rowData?.products?.map((p) => {
      return p?.productId.products_name;
    });

    return <div>{pro.join(",")}</div>;
  };

  const invoice = (rowData) => {
    return (
      <Button
        label="Invoice "
        // icon="pi pi-arrow-left"
        onClick={() => handleInvoice(rowData)}
        className="p-button-primary mr-3"
      />
    );
  };
  const handleInvoice = (rowData) => {
    setShowInvoice(true);
    setSelectedRow(rowData);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    "client.Clientname": {
      value: null,
      matchMode: FilterMatchMode.STARTS_WITH,
    },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  // custom function ENDED

  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>
      <Dialog
        visible={showInvoice}
        position={position}
        modal
        style={{ width: "65vw" }}
        onHide={() => setShowInvoice(false)}
        draggable={false}
        resizable={false}
      >
        <Invoice selectedRow={selectedRow} title="client" />
      </Dialog>

      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Client Order </h3>
              <div className="flex  align-items-center">
                <div className="flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder=" Search"
                    />
                  </span>
                </div>
                <Button
                  label="Add new "
                  // icon="pi pi-arrow-left"
                  onClick={() => onClick("displayPosition", "center")}
                  className="p-button-primary mr-3 ml-3"
                />
              </div>
            </div>
          </div>

          <Dialog
            header={edittable ? "Edit  Details " : "Client Order detail "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <ClientFormOrder
              onHide={onHide}
              getAllClientsOrders={getAllClientsOrders}
            />
          </Dialog>
        </div>
      </div>

      {/* table of clients order  */}
      <div className="card">
        <DataTable
          value={clientOrderData}
          loading={loading}
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          rows={5}
          filters={filters}
        >
          <Column field="client.Clientname" header="Client" />
          <Column field="totalBill" header="Total Bill" />

          <Column field="amountToBePaid" header="Amount To Be Paid (PKR)" />
          <Column body={product} header="Product " />
          <Column body={invoice} header=" Invoice" />
        </DataTable>
      </div>
    </>
  );
};

export default ClientOrder;
