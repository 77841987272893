import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Axios from "axios";
import ForgotPassword from "./forgetpassword/Forgetpassword";

const Login = () => {
  const history = useHistory();
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .max(100, "Maximum length 100 allowed"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: async (data) => {
      setSaveBtnLoading(true);
      const obj = {
        email: formik.values.email,
        password: formik.values.password,
      };
      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_Url}/api/login`,
          obj
        );
        if (res.status === 200) {
          localStorage.setItem("login", true);
          localStorage.setItem("accessToken", res.data?.accessToken);
          let str = JSON.stringify(res?.data?.data?.userModPermission?.modules);
          let temp = JSON.stringify([{ s: "dashboard" }]);
          localStorage.setItem(
            "modulesAccess",
            typeof str !== "undefined" ? str : temp
          );
          toast.success(res.data?.message);

          history.push("/");
        }
        if (res.status === 203) {
          toast.warn(res.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setSaveBtnLoading(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    return () => {
      // Cleanup function
      setSaveBtnLoading(false);
    };
  }, []);
  const handleForgotPassword = async () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <>
      {!forgotPassword ? (
        <form
          onSubmit={formik.handleSubmit}
          style={{
            background: "#91B8F3",
            display: "flex",
          }}
        >
          <div className="lg:flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden ">
            <div>
              <h1
                style={{
                  fontSize: "6rem",
                  marginRight: "4rem",
                  fontFamily: "Lucida Handwriting",
                }}
              >
                Mediko
              </h1>
              <h1
                style={{
                  fontSize: "6rem",
                  marginRight: "4rem",
                  fontFamily: "Lucida Handwriting",
                }}
              >
                {" "}
              </h1>
            </div>

            <div className="flex flex-column align-items-right justify-content-right">
              <div
                style={{
                  borderRadius: "56px",
                  padding: "0.3rem",
                  background:
                    "linear-gradient(180deg, #407772 10%, rgba(33, 150, 243, 0) 30%)",
                  width: "30rem",
                }}
              >
                <div
                  className="w-full surface-card py-8 px-5 sm:px-8"
                  style={{ borderRadius: "53px" }}
                >
                  <div className="text-center mb-5">
                    {/* <img src="assets/layout/images/Zindigi.png" alt="Image" height="50" className="mb-3" /> */}
                    <div className=" text-3xl font-medium mb-3">Sign in</div>
                    <span className="text-600 font-medium">
                      Sign in to continue
                    </span>
                  </div>

                  <div className="login-div">
                    <label
                      htmlFor="email1"
                      className="block  text-xl font-medium mb-2"
                    >
                      Email
                    </label>
                    <InputText
                      id="email"
                      name="email"
                      value={formik.values.email || ""}
                      onChange={formik.handleChange}
                      type="email"
                      autoComplete="off"
                      className="w-full mb-3"
                    />
                    {getFormErrorMessage("email")}

                    <label
                      htmlFor="password1"
                      className="block  font-medium text-xl mb-2"
                    >
                      Password
                    </label>
                    <Password
                      id="password"
                      name="password"
                      value={formik.values.password || ""}
                      onChange={formik.handleChange}
                      toggleMask
                      autoComplete="off"
                      feedback={false}
                      className="w-full mb-3"
                    />
                    {getFormErrorMessage("password")}

                    <div className="flex align-items-center justify-content-between mb-5 gap-5">
                      {/* <div className="flex align-items-center">
                      <Checkbox
                        inputid="rememberme1"
                        checked={checked}
                        onChange={(e) => setChecked(e.checked)}
                        className="mr-2"
                      ></Checkbox>
                      <label htmlFor="rememberme1">Remember me</label>
                    </div> */}
                      <div
                        className="font-medium no-underline ml-2 text-right cursor-pointer"
                        style={{ color: "var(--primary-color)" }}
                        onClick={handleForgotPassword}
                      >
                        Forgot password?
                      </div>
                    </div>
                    <Button
                      label="Sign in"
                      loading={saveBtnLoading}
                      type="submit"
                      className="w-full mt-3 p-3 text-xl"
                    />
                    {/* <p>
                                        By selecting an account, you agree to our <a href="https://www.primefaces.org/primereact-v7/#/formlayout">Term</a> <br /> and acknowledge our <a href="https://www.primefaces.org/primereact-v7/#/formlayout">Privacy Statements.</a>
                                    </p> */}

                    {/* <div
                                        className="row p-2"
                                        style={{
                                            borderStyle: "solid",
                                            borderColor: "#fff",
                                        }}
                                    >
                                        <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                            <i className="pi pi-user-plus"></i>
                                        </div>

                                        <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8">
                                            <p>Use a Different Account</p>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2">
                                            <a href="https://www.primefaces.org/primereact-v7/#/formlayout">
                                                {" "}
                                                <i className="pi pi-angle-right"></i>
                                            </a>
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <ForgotPassword handleForgotPassword={handleForgotPassword} />
      )}
    </>
  );
};

export default Login;
