import React, { useEffect, useState } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import axios from "axios";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import "./style.css";
const CustForm = ({
  geteditdata,
  editable,
  editProduct,
  onHide,
  getAllCustomers,
}) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const [prouctOptions, setProductOption] = useState();
  const [productId, setProductId] = useState();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, "Maximum length 100 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "invalid name")
      .required("This field is required."),
    email: Yup.string()
      .email("Invalid email")
      .max(100, "Maximum length 100 allowed")
      .required("This field is required."),
    address: Yup.string()
      .max(100, "Maximum length 100 allowed")
      .required("This field is required."),
    contact: Yup.string()
      .matches(/^03[0-9]{9}$/, "invalid number")
      .min(11, "Min length 11 allowed")
      .max(11, "Maximum length 11 allowed")
      .required("This field is required."),
    salesPerson: Yup.string().required("This field is required."),
    area: Yup.string().required("This field is required."),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    const productsArray = geteditdata?.products;
    const uniqueIdSet = new Set(
      productsArray?.map((item) => item.productName._id)
    );

    // Convert the Set back to an array
    const newArray = Array.from(uniqueIdSet);
    setProductId(newArray);

    if (editable === true || editProduct === true) {
      formik.setFieldValue("name", geteditdata?.customerName);
      formik.setFieldValue("address", geteditdata?.address);
      formik.setFieldValue("email", geteditdata?.email);
      formik.setFieldValue("contact", geteditdata?.contact);
      formik.setFieldValue("salesPerson", geteditdata?.salesPerson);
      formik.setFieldValue("area", geteditdata?.area);
      formik.setFieldValue("products", newArray);
    }
  }, [editable]);

  useEffect(() => {
    // Make an API request to fetch company data
    axios
      .get(`${process.env.REACT_APP_Url}/api/product/`)
      .then((response) => {
        const prod = response.data;

        const options = prod?.map((product) => ({
          name: product.products_name, // Displayed text in the dropdown
          code: product._id, // Value associated with the option
        }));

        setProductOption(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      address: "",
      email: "",
      contact: "",
      salesPerson: "",
      area: "",
      // products: "",
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      let v = values.products;
      const modifiedArray = [];
      const uniqueValues = new Set(); // Create a Set to store unique values

      // for (let i = 0; i < v.length; i++) {
      //   const originalItem = v[i];

      //   // Check if the value is not a duplicate
      //   if (!uniqueValues.has(originalItem)) {
      //     // Modify the item as needed
      //     const modifiedItem = { productName: originalItem };

      //     // Add the modified item to the new array
      //     modifiedArray.push(modifiedItem);

      //     // Add the value to the Set to track uniqueness
      //     uniqueValues.add(originalItem);
      //   }
      // }

      setSaveBtnLoading(true);
      if (editable === true) {
        const obj = {
          customerName: formik.values.name,
          email: formik.values.email,
          address: formik.values.address,
          contact: formik.values.contact,
          area: formik.values.area,
          salesPerson: formik.values.salesPerson,
        };

        try {
          const res = await Axios.put(
            `${process.env.REACT_APP_Url}/api/customer/update/${geteditdata?._id}`,
            obj
          );

          if (res.status === 200) {
            toast.success("success");
            await getAllCustomers();
            onHide();
          } else {
            console.log("false");
          }
        } catch {
          console.log("error");
        }
        onHide();
        getAllCustomers();
      } else {
        const obj = {
          customerName: formik.values.name,
          email: formik.values.email,
          area: formik.values.area,
          salesPerson: formik.values.salesPerson,
          address: formik.values.address,
          contact: formik.values.contact,
        };

        try {
          const res = await Axios.post(
            `${process.env.REACT_APP_Url}/api/customer/Add`,
            obj
          );

          if (res.status === 200 || res.status === 201) {
            toast.success("success");
            onHide();
            await getAllCustomers();
          } else {
            console.log("false");
          }
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }

        getAllCustomers();
      }
    },
  });

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("name") })}
            >
              Customer Name*
            </label>

            <InputText
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("name") },
                ""
              )}
            />
            {getFormErrorMessage("name")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("contact"),
              })}
            >
              Contact*
            </label>
            <InputText
              id="contact"
              type="contact"
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("contact") },
                ""
              )}
            />
            {getFormErrorMessage("contact")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("email") })}
            >
              Email*
            </label>
            <InputText
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("email") },
                ""
              )}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("address") })}
            >
              Address*
            </label>
            <InputText
              id="address"
              type="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("address") },
                ""
              )}
            />
            {getFormErrorMessage("address")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("salesPerson"),
              })}
            >
              Sales person*
            </label>
            <InputText
              id="salesPerson"
              type="salesPerson"
              name="salesPerson"
              value={formik.values.salesPerson}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("salesPerson") },
                ""
              )}
            />
            {getFormErrorMessage("salesPerson")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("area") })}
            >
              Area*
            </label>
            <InputText
              id="area"
              type="area"
              name="area"
              value={formik.values.area}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("area") },
                ""
              )}
            />
            {getFormErrorMessage("area")}
          </div>
        </div>

        {/* {
          <div className="p-fluid p-formgrid p-grid grid row">
            <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
              <label
                className={classNames({
                  "p-error": isFormFieldValid("products"),
                })}
              >
                Products*
              </label>

              <MultiSelect
                value={formik.values.products}
                onChange={(e) => {
                  formik.setFieldValue("products", e.value);
                }}
                options={prouctOptions}
                optionLabel="name"
                display="chip"
                id="products"
                placeholder="Select  products"
                name="products"
                optionValue="code"
                className="customMultiselect"
                // className="p-multiselect-panel p-multiselect-items "
                // axSelectedLabels={3}
              />
              {getFormErrorMessage("products")}
            </div>
          </div>
        } */}

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default CustForm;
