import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import AddPermission from "./AddPermission";

const ModulePermissionForm = ({ onHide, getAllPermission, selectedRow }) => {
  const [modulesOption, setModulesOption] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [permissionValue, setPermissionValue] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const validationSchema = Yup.object().shape({});
  useEffect(() => {
    // Make an API request to fetch company data
    Axios.get(`${process.env.REACT_APP_Url}/api/permission/getAllPermissions`)
      .then((response) => {
        const per = response?.data?.data;

        const options = per?.map((p) => ({
          name: p?.permissionName, // Displayed text in the dropdown
          code: p?._id, // Value associated with the option
        }));
        setPermissionValue(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_Url}/api/module`)
      .then((response) => {
        const per = response?.data?.data;

        setModulesOption(per);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);

  const allowExpansion = (rowData) => {
    return <>{rowData?.length > 0}</>;
  };

  const expandedTableTemp = (rowData) => {
    return (
      <div className="field-checkbox">
        <AddPermission
          rowData={rowData}
          key={rowData?._id}
          permissionValue={permissionValue}
          setSelectedPermissions={setSelectedPermissions}
          selectedPermissions={selectedPermissions}
        />
      </div>
    );
  };

  const submission = async () => {
    setSaveBtnLoading(true);
    if (selectedRow?.userModPermission?.modules?.length >= 1) {
      let mapedData = selectedPermissions.map((item) => {
        return {
          module: item.module,
          permissions: item.permissions.map((permission) => {
            return permission.code;
          }),
        };
      });
      const obj = {
        status: "true",
        modules: mapedData,
      };

      try {
        const res = await Axios.put(
          `${process.env.REACT_APP_Url}/api/UserModPermission/update/${selectedRow?._id}`,
          obj
        );

        if (res.status === 200) {
          toast.success("success");
          await getAllPermission();
          onHide();
        } else {
          console.log("error");
        }
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaveBtnLoading(false);
      }
    } else {
      let mapedData = selectedPermissions.map((item) => {
        return {
          module: item.module,
          permissions: item.permissions.map((permission) => {
            return permission.code;
          }),
        };
      });

      const obj = {
        userId: selectedRow?._id, // Replace with a valid user ID
        modules: mapedData,
      };
      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_Url}/api/UserModPermission/Add`,
          obj
        );

        if (res.status === 200 || res.status === 201) {
          toast.success("success");
          await getAllPermission();
          onHide();
        } else {
          console.log("error");
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setSaveBtnLoading(false);
      }
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      supplierName: "",
    },

    validate: (data) => {},
  });

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            {/* <label className={classNames({ "p-error": isFormFieldValid("module") })}> Modules</label>
                      
                        {getFormErrorMessage("module")} */}

            <DataTable
              expandedRows={expandedRows}
              lazy
              // filterDisplay="row"
              responsiveLayout="scroll"
              value={modulesOption}
              rows={5}
              rowExpansionTemplate={expandedTableTemp}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
            >
              <Column expander={allowExpansion} style={{ width: "3em" }} />
              <Column field="moduleName" header="Module Name" />
            </DataTable>
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            onClick={submission}
            className="p-button-rounded p-button-primary"
          />
        </div>
      </form>
    </div>
  );
};

export default ModulePermissionForm;
