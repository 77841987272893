import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import UserForm from "./UserForm";
import UserDetail from "./UserDetail";
import { toast } from "react-toastify";

import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
function CustomerData() {
  // use states
  const [cusData, setCusData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [edittable, setedittable] = useState(false); //For edit form opening
  const [editdata, seteditdata] = useState();
  const [permission, setPermissions] = useState();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null); //For getting data in edit form

  const [displayDialog, setDisplayDialog] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  // use effect
  useEffect(() => {
    getAllUsers();
  }, []);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.IN },
  });
  // use effect ended

  // custom functions
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const editfunc = (rowData) => {
    setDisplayPosition(true);
    setPosition("center");
    setedittable(true);
    seteditdata(rowData);
  };
  const handleViewDetails = (rowData) => {
    setDisplayDialog(true);
    setUserDetail(rowData);
    setUserId(rowData?._id);
  };

  const icon = (rowData) => {
    let mod = JSON.parse(localStorage.getItem("modulesAccess"));
    const f = mod.filter((m) => m?.module?.moduleName === "User");
    let permissions = f[0].permissions.map((p) => {
      return p.permissionName;
    });

    return (
      <div>
        {permissions?.includes("Edit") && (
          <button
            className="pi pi-pencil ml-2 p-mr-2 edit-icon-background"
            onClick={() => editfunc(rowData)}
          ></button>
        )}
        {permissions?.includes("Delete") && (
          <button
            className="pi pi-trash ml-2 p-ml-2 delete-icon-background"
            onClick={() => deleteUserData(rowData)}
          ></button>
        )}

        {permissions?.includes("View") && (
          <button
            className="pi pi-eye edit ml-2 p-ml-2 p-button-rounded edit-icon-background"
            onClick={() => handleViewDetails(rowData)}
            tooltip="user detail"
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    let mod = JSON.parse(localStorage.getItem("modulesAccess"));
    const f = mod?.filter((m) => m?.module?.moduleName === "User");
    let permissions = f[0].permissions.map((p) => {
      return p.permissionName;
    });

    setPermissions(permissions);
  }, [localStorage.getItem("modulesAccess")]);

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);
  };

  // Getting user data
  const getAllUsers = async () => {
    setLoading(true);
    const response = await Axios.get(`${process.env.REACT_APP_Url}/api/user`);
    if (response.status === 200) {
      const { data } = response;
      const reversedData = data.reverse();
      setCusData(reversedData);
      setLoading(false);
    } else {
      console.log("false");
      setLoading(false);
    }
  };
  // Getting customer data  ends

  const confirmDelete = async () => {
    setSaveBtnLoading(true);
    try {
      if (selectedRowToDelete) {
        const response = await Axios.delete(
          `${process.env.REACT_APP_Url}/api/user/${selectedRowToDelete._id}`
        );
        if (response.status === 200) {
          getAllUsers();
          toast.success("Deleted Successfully");
        }
      }
      setDeleteConfirmationVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setSaveBtnLoading(false);
    }
  };

  const deleteUserData = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDeleteConfirmationVisible(true);
  };
  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button
        label="Cancel"
        className="p-button-secondary"
        onClick={() => setDeleteConfirmationVisible(false)}
      />
      <Button
        label="Delete"
        className="p-button-danger"
        onClick={confirmDelete}
        loading={saveBtnLoading}
      />
    </div>
  );

  // custom function ENDED

  const email = (rowData) => {
    return <div>{rowData.email.toLowerCase()}</div>;
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>
      <Dialog
        draggable={false}
        visible={displayDialog}
        style={{ width: "70vw" }}
        onHide={() => setDisplayDialog(false)}
      >
        <UserDetail onHide={onHide} userDetail={userDetail} userId={userId} />
      </Dialog>

      {/* code for add new customer button  */}
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">User Management </h3>
              <div className="flex  align-items-center">
                <div className="flex justify-content-end">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      placeholder=" Search"
                    />
                  </span>
                </div>
                {permission?.includes("Add") && (
                  <Button
                    label="Add new "
                    // icon="pi pi-arrow-left"
                    onClick={() => onClick("displayPosition", "center")}
                    className="p-button-primary mr-3 ml-2"
                  />
                )}
              </div>
            </div>
          </div>

          <Dialog
            header={edittable ? "Edit User Details " : "Add User Details "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <UserForm
              geteditdata={editdata}
              editable={edittable}
              onHide={onHide}
              getAllUsers={getAllUsers}
            />
          </Dialog>
        </div>
      </div>
      {/* add new button ended  */}

      {/* table of customers  */}
      <div className="card">
        <DataTable
          value={cusData}
          loading={loading}
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          filters={filters}
          rows={5}
          paginator
        >
          <Column field="username" header="User Name" />
          <Column field={email} header="Email" />

          <Column field="RoleId.name" header="Role" />
          <Column className="ml-4" header="Action" body={icon} />
        </DataTable>
      </div>
      {/* table of customer ended  */}
    </>
  );
}

export default CustomerData;
