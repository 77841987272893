import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Chart } from "primereact/chart";
import axios from "axios";

export default function Dashboard() {
  const [productCount, setProductCount] = useState();
  const [userCount, setUserCount] = useState();
  const [customerData, setCustomerData] = useState();
  const [supplierDat, setSupplierData] = useState();
  const [clientData, setCLientData] = useState();
  // Doughnut and pie
  const userData = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        data: [userCount?.activeUserCount, userCount?.inactiveUserCount],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };

  const supplierData = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        data: [
          supplierDat?.activeSupplierCount,
          supplierDat?.inactiveSupplierCount,
        ],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };
  const lightOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  };
  const getProductData = () => {
    axios
      .get(`${process.env.REACT_APP_Url}/api/product/count`)
      .then((response) => {
        setProductCount(response?.data?.totalProductCount);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const getUserData = () => {
    axios
      .get(`${process.env.REACT_APP_Url}/api/user/count`)
      .then((response) => {
        setUserCount(response.data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };
  const getSupplierData = () => {
    axios
      .get(`${process.env.REACT_APP_Url}/api/supplier/count`)
      .then((response) => {
        setSupplierData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };
  const getcustomer = () => {
    axios
      .get(`${process.env.REACT_APP_Url}/api/customer/count`)
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const getClient = () => {
    axios
      .get(`${process.env.REACT_APP_Url}/api/client/count`)
      .then((response) => {
        setCLientData(response?.data?.TotalClients);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  useEffect(() => {
    getProductData();
    getUserData();
    getSupplierData();
    getcustomer();
    getClient();
  }, []);

  return (
    <div>
      <div className="row doughnut martop ml-1 mr-1">
        <div>
          <h1 style={{ color: "black" }}>Dashboard</h1>
          <div className="grid p-2 pb-0">
            <div
              className="card col bg-1 ml-2"
              style={{ maxHeight: "150px", overflow: "hidden" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5>Product count</h5>
                </div>
                <h5 style={{ textAlign: "left" }}>{productCount}</h5>
              </div>
            </div>
            <div
              className="card col bg-2 ml-2"
              style={{ maxHeight: "150px", overflow: "hidden" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5>User count</h5>
                </div>
                <h5 style={{ textAlign: "left" }}>
                  {userCount?.totalUserCount}
                </h5>
              </div>
            </div>
            <div
              className="card col bg-3 ml-2"
              style={{ maxHeight: "150px", overflow: "hidden" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5> Supplier count</h5>
                </div>

                <h5 style={{ textAlign: "left" }}>
                  {supplierDat?.totalSupplierCount}
                </h5>
              </div>
            </div>
            <div
              className="card col bg-4 ml-2"
              style={{ maxHeight: "150px", overflow: "hidden" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5> Customer count</h5>
                </div>
                <h5 style={{ textAlign: "left" }}>
                  {customerData?.TotalCustomers}
                </h5>
              </div>
            </div>

            {/* <div
              className="card col bg-5 ml-2"
              style={{ maxHeight: "150px", overflow: "hidden" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h5>Client data</h5>
                </div>
                <h5 style={{ textAlign: "left" }}>{clientData}</h5>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row lg:col-6 md:col-4 xs:col-4 sm:col-4">
          <div className="card item-center flex align-items-center">
            <h4>User chart</h4>
            {userData?.datasets[0]?.data[0] === 0 &&
            userData?.datasets[0]?.data[1] === 0 ? (
              <h2>No data found</h2>
            ) : (
              <Chart
                type="doughnut"
                data={userData}
                options={lightOptions}
                style={{ position: "relative", width: "50%" }}
              />
            )}
          </div>
        </div>
        <div className="row  lg:col-6 md:col-4 xs:col-4 sm:col-4 ">
          <div className=" card doughnut flex align-items-center">
            <h4>Supplier chart</h4>

            {supplierData?.datasets[0]?.data[0] === 0 &&
            supplierData?.datasets[0]?.data[1] === 0 ? (
              <>
                <h5 style={{ marginBottom: "10rem" }}> No data found</h5>
              </>
            ) : (
              <Chart
                type="pie"
                data={supplierData}
                options={lightOptions}
                style={{ position: "relative", width: "50%" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
