import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";

const CompanyDetail = ({ onHide, companyId }) => {
  const [companyDetail, setCompanyDetail] = useState([]);
  // use states

  // custom function ENDED
  useEffect(() => {
    // Make an API request to fetch company data
    Axios.get(`${process.env.REACT_APP_Url}/api/company/${companyId}`)
      .then((response) => {
        setCompanyDetail([response?.data?.Company]);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
        onHide();
      });
  }, []);
  return (
    <>
      <div className="card">
        <h3 className="mt-2">Company Detail </h3>
        <DataTable
          value={companyDetail}
          lazy
          // filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          rows={5}
        >
          <Column field="companyName" header=" Company Name" />
          <Column field="email" header=" Email" />
          <Column field="address" header="Address" />
          <Column field="phone" header="Phone" />
          <Column field="website" header="Website" />
          <Column field="fax" header="fax" />
          <Column field="certifications" header="Certification" />
          <Column field="website" header="Website" />
        </DataTable>
      </div>
      {/* table of customer ended  */}
    </>
  );
};

export default CompanyDetail;
