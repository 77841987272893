import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import moment from "moment";

const CustomerDetail = ({ onHide, customerId }) => {
  const [customerData, setCustomerData] = useState([]);
  // use states

  // custom function ENDED
  useEffect(() => {
    // Make an API request to fetch company data
    Axios.get(`${process.env.REACT_APP_Url}/api/customerOrder/${customerId}`)
      .then((response) => {
        setCustomerData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
        onHide();
      });
  }, []);
  const product = (rowData) => {
    const pro = rowData?.products?.map((p) => {
      return p?.productId?.products_name;
    });

    return <div>{pro.join(",")}</div>;
  };
  const date = (rowData) => {
    const dat = rowData?.createdAt;
    return <div>{moment(dat).format("YYYY-MM-DD")}</div>;
  };
  const amount = (rowData) => {
    const amount = rowData?.amountToBePaid.toFixed(2);
    return <div>{amount}</div>;
  };
  return (
    <>
      <div className="card">
        <h3 className="mt-2">Customer Orders </h3>
        <DataTable
          value={customerData}
          lazy
          // filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          rows={5}
        >
          <Column field="customer.customerName" header=" Customer Name" />
          <Column field={product} header=" Product Name" />
          <Column field="totalBill" header=" Total Bill" />
          <Column field={amount} header="Amount To Be Paid (PKR)" />
          <Column field={date} header="Created At" />
          <Column field="discount" header="Discount" />
        </DataTable>
      </div>
    </>
  );
};

export default CustomerDetail;
