import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import RoleForm from "./RoleForm";
import { toast } from "react-toastify";
import { Paginator } from "primereact/paginator";
const RoleData = () => {
  // use states
  const [roleData, setRoleData] = useState([]);
  const [displayPosition, setDisplayPosition] = useState(false); //For form Position
  const [position, setPosition] = useState(""); //For
  const [edittable, setedittable] = useState(false); //For edit form opening
  const [editdata, seteditdata] = useState();
  const [loading, setLoading] = useState();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [selectedRowToDelete, setSelectedRowToDelete] = useState(null); //For getting data in edit form
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  // use effect

  const getAllRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Url}/api/role/getAll`
      );
      if (response.status === 200) {
        // const { data } = response;
        setRoleData(response?.data?.data);
        setLoading(false);
      } else {
        console.log("false");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllRoles();
  }, []);
  // use effect ended

  // custom functions
  const dialogFuncMap = {
    displayPosition: setDisplayPosition,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const icon = (rowData) => {
    return (
      <div>
        <button
          className="pi pi-trash ml-2 p-ml-2 delete-icon-background"
          onClick={() => deleteUserData(rowData)}
        ></button>
      </div>
    );
  };

  const onHide = (name) => {
    setDisplayPosition(false);
    setPosition(false);
    setedittable(false);
  };

  const deleteUserData = (rowData) => {
    setSelectedRowToDelete(rowData);
    setDeleteConfirmationVisible(true);
  };

  const confirmDelete = async () => {
    setSaveBtnLoading(true);
    try {
      if (selectedRowToDelete) {
        const response = await axios.delete(
          `${process.env.REACT_APP_Url}/api/role/delete/${selectedRowToDelete._id}`
        );
        if (response.status === 200) {
          getAllRoles();
          toast.success("Deleted Successfully");
        }
      }
      setDeleteConfirmationVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setSaveBtnLoading(false);
    }
  };
  const renderDeleteConfirmationFooter = () => (
    <div>
      <Button
        label="Cancel"
        className="p-button-secondary"
        onClick={() => setDeleteConfirmationVisible(false)}
      />
      <Button
        label="Delete"
        className="p-button-danger"
        onClick={confirmDelete}
        loading={saveBtnLoading}
      />
    </div>
  );

  const name = (rowData) => {
    return <>{rowData.name.toUpperCase()}</>;
  };

  // custom function ENDED

  return (
    <>
      <Dialog
        header="Confirm Delete"
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        modal
        footer={renderDeleteConfirmationFooter()}
      >
        <p>Are you sure you want to delete this record?</p>
      </Dialog>

      {/* code for add new customer button  */}
      <div className="martop dialog-demo">
        <div className="card">
          <div className="p-grid p-dir-col">
            <div className="p-col addnewbutton">
              <h3 className="mt-2">Role Management </h3>
              <div className="flex">
                <Button
                  label="Add new "
                  // icon="pi pi-arrow-left"
                  onClick={() => onClick("displayPosition", "center")}
                  className="p-button-primary mr-3"
                />
              </div>
            </div>
          </div>

          <Dialog
            header={edittable ? "Edit  Details " : "Add Role "}
            visible={displayPosition}
            position={position}
            modal
            style={{ width: "65vw" }}
            onHide={() => onHide()}
            draggable={false}
            resizable={false}
          >
            <RoleForm
              editable={edittable}
              onHide={onHide}
              getAllRoles={getAllRoles}
            />
          </Dialog>
        </div>
      </div>
      {/* add new button ended  */}

      {/* table of customers  */}
      <div className="card">
        <DataTable
          value={roleData}
          loading={loading}
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          rows={5}
        >
          <Column field={name} header="Role " />

          <Column className="ml-4" header="Action" body={icon} />
        </DataTable>
      </div>
      {/* table of customer ended  */}
    </>
  );
};

export default RoleData;
