import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
export default function CompanyForm({
  geteditdata,
  editable,
  onHide,
  getAllCompanyData,
}) {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [cityOption, setCityOption] = useState();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(25, "Maximum length 25 allowed")
      .required("This field is required."),
    email: Yup.string()
      .email("Invalid email")
      .required("This field is required.")
      .max(100, "Maximum length 100 allowed"),
    address: Yup.string()
      .max(100, "Maximum length 100 allowed")
      .required("This field is required."),
    city: Yup.string()
    .required("This field is required."),
    phone: Yup.string()
      .matches(/^03[0-9]{9}$/, "invalid number")
      .min(11, "Min length 11 allowed")
      .max(11, "Maximum length 11 allowed")
      .required("This field is required."),
    website: Yup.string()
      .max(25, "Maximum length 25 allowed")
      .required("This field is required."),
    registrationNo: Yup.string()
      .max(25, "Maximum length 25 allowed")
      .required("This field is required."),
    certifications: Yup.string().max(25, "Maximum length 25 allowed"),
    fax: Yup.string().max(25, "Maximum length 25 allowed"),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (editable === true) {
      formik.setFieldValue("name", geteditdata?.companyName);
      formik.setFieldValue("address", geteditdata?.address);
      formik.setFieldValue("email", geteditdata?.email);
      formik.setFieldValue("phone", geteditdata?.phone);
      formik.setFieldValue("city", geteditdata?.city);
      formik.setFieldValue("registrationNo", geteditdata?.registrationNo);
      formik.setFieldValue("website", geteditdata?.website);
      formik.setFieldValue("certifications", geteditdata?.certifications);
      formik.setFieldValue("fax", geteditdata?.fax);
    }
  }, [editable]);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      address: "",
      email: "",
      registrationNo: "",
      city: "",
      website: "",
      phone: "",
      certifications: "",
      fax: "",
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      if (editable === true) {
        const obj = {
          companyName: formik.values.name,
          email: formik.values.email,
          address: formik.values.address,
          registrationNo: formik.values.registrationNo,
          city: formik.values.city,
          website: formik.values.website,
          phone: formik.values.phone,
          certifications: formik.values.certifications,
          fax: formik.values.fax,
        };

        try {
          const res = await Axios.put(
            `${process.env.REACT_APP_Url}/api/company/${geteditdata?._id}`,
            obj
          );

          if (res.status === 200) {
            toast.success("success");
            await getAllCompanyData();
            onHide();
          } else {
            console.log("false");
          }
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllCompanyData();
      } else {
        const obj = {
          companyName: formik.values.name,
          email: formik.values.email,
          address: formik.values.address,
          registrationNo: formik.values.registrationNo,
          city: formik.values.city,
          phone: formik.values.phone,
          website: formik.values.website,
          certifications: formik.values.certifications,
          fax: formik.values.fax,
        };

        try {
          const res = await Axios.post(
            `${process.env.REACT_APP_Url}/api/company`,
            obj
          );

          if (res.status === 200 || res.status === 201) {
            toast.success("success");
            await getAllCompanyData();
            onHide();
          } else {
            console.log("false");
          }
        } catch (err) {
          toast.error(err.response.data.message);
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllCompanyData();
      }
    },
  });
  ///cities
  useEffect(() => {
    Axios.get(
      `https://officeautomation-node.appinsnap.com/api/lovList/listOfCities`
    )
      .then((response) => {
        const cities = response.data;

        const options = cities.map((city) => ({
          label: city?.city_name, // Displayed text in the dropdown
          value: city?.id, // Value associated with the option
        }));

        setCityOption(options);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);
  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("name") })}
            >
              Company Name
            </label>

            <InputText
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("name") },
                ""
              )}
            />
            {getFormErrorMessage("name")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("email") })}
            >
              Email{" "}
            </label>
            <InputText
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("email") },
                ""
              )}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("address") })}
            >
              Address{" "}
            </label>
            <InputText
              id="address"
              type="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("address") },
                ""
              )}
            />
            {getFormErrorMessage("address")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("registrationNo"),
              })}
            >
              Registration No{" "}
            </label>
            <InputText
              id="registrationNo"
              type="registrationNo"
              name="registrationNo"
              value={formik.values.registrationNo}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("registrationNo") },
                ""
              )}
            />
            {getFormErrorMessage("registrationNo")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("city") })}
            >
              City{" "}
            </label>

            <Dropdown
              id="city"
              placeholder="Select a City"
              name="city"
              value={formik.values.city}
              options={cityOption} // Use the options fetched from the API
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("city") },
                ""
              )}
            />

            {getFormErrorMessage("city")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("phone") })}
            >
              Phone{" "}
            </label>
            <InputText
              id="phone"
              type="phone"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("phone") },
                ""
              )}
            />
            {getFormErrorMessage("phone")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("website") })}
            >
              Website{" "}
            </label>
            <InputText
              id="website"
              type="website"
              name="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("website") },
                ""
              )}
            />
            {getFormErrorMessage("website")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("fax") })}
            >
              Fax{" "}
            </label>
            <InputText
              id="fax"
              type="fax"
              name="fax"
              value={formik.values.fax}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("fax") },
                ""
              )}
            />
            {getFormErrorMessage("fax")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("certifications"),
              })}
            >
              Certifications{" "}
            </label>
            <InputText
              id="certifications"
              type="certifications"
              name="certifications"
              value={formik.values.certifications}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("certifications") },
                ""
              )}
            />
            {getFormErrorMessage("certifications")}
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
}
