import React, { useEffect, useState } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import { toast } from "react-toastify";
const SupplierForm = ({ geteditdata, editable, onHide, getAllSuppliers }) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    supplierName: Yup.string()
      .max(50, "Maximum length 50 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "invalid name")
      .required("This field is required."),
    email: Yup.string()
      .email("Invalid email")
      .max(100, "Maximum length 100 allowed")
      .required("This field is required."),
    address: Yup.string().when("supplierName", {
      is: "rider",
      then: Yup.string().required("Required"),

      otherwise: Yup.string(),
    }),
    contact: Yup.string()
      .matches(/^03[0-9]{9}$/, "invalid number")
      .min(11, "Min length 11 allowed")
      .max(11, "Maximum length 11 allowed")
      .required("This field is required."),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (editable === true) {
      formik.setFieldValue("supplierName", geteditdata?.supplierName);
      formik.setFieldValue("address", geteditdata?.address);
      formik.setFieldValue("email", geteditdata?.email);
      formik.setFieldValue("contact", geteditdata?.contact);
    }
  }, [editable]);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      supplierName: "",
      address: "",
      email: "",
      contact: "",
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      if (editable) {
        const obj = {
          supplierName: formik.values.supplierName,
          email: formik.values.email,
          address: formik.values.address,
          contact: formik.values.contact,
        };

        try {
          const res = await Axios.put(
            `${process.env.REACT_APP_Url}/api/supplier/update/${geteditdata?._id}`,
            obj
          );

          if (res.status === 200) {
            toast.success("success");
            await getAllSuppliers();
            onHide();
          } else {
          }
        } catch {
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllSuppliers();
      } else {
        const obj = {
          supplierName: formik.values.supplierName,
          email: formik.values.email,
          address: formik.values.address,
          contact: formik.values.contact,
        };

        try {
          const res = await Axios.post(
            `${process.env.REACT_APP_Url}/api/supplier/Add`,
            obj
          );
          if (res.status === 200 || res.status === 201) {
            await getAllSuppliers();
            toast.success("success");
            onHide();
          }
        } catch (err) {
          toast.error(err.response.data.message);
          console.log("error");
        } finally {
          setSaveBtnLoading(false);
        }
        onHide();
        getAllSuppliers();
      }
    },
  });

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({
                "p-error": isFormFieldValid("supplierName"),
              })}
            >
              Supplier Name*
            </label>

            <InputText
              id="supplierName"
              name="supplierName"
              value={formik.values.supplierName}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("supplierName") },
                ""
              )}
            />
            {getFormErrorMessage("supplierName")}
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid grid row">
          {/* {editable === true ? null : ( */}
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("contact") })}
            >
              Contact*
            </label>
            <InputText
              id="contact"
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("contact") },
                ""
              )}
            />
            {getFormErrorMessage("contact")}
          </div>
          {/* )} */}
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("email") })}
            >
              Email*
            </label>
            <InputText
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("email") },
                ""
              )}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>

        <div className="p-fluid p-formgrid p-grid grid row">
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("address") })}
            >
              Address*
            </label>
            <InputText
              id="address"
              type="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("address") },
                ""
              )}
            />
            {getFormErrorMessage("address")}
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default SupplierForm;
