import React, { useState, useEffect } from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import { Button } from "primereact/button";
import classNames from "classnames";
import { toast } from "react-toastify";
const CustForm = ({ onHide, getAllRoles }) => {
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(35, "Maximum length 35 allowed")
      .matches(/^[a-zA-Z][a-zA-Z ]+$/, "invalid name")
      .required("This field is required."),
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error mt-1 ml-2">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
    },

    validate: (data) => {},

    onSubmit: async (values) => {
      setSaveBtnLoading(true);
      const obj = {
        name: values.name,
      };

      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_Url}/api/role/addrole`,
          obj
        );

        if (res.status === 200 || res.status === 201) {
          toast.success("Role added succesfully");
          await getAllRoles();
          onHide();
        } else {
          console.log("false");
        }
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setSaveBtnLoading(false);
      }
      onHide();
      getAllRoles();
    },
  });

  return (
    <div className="card">
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-fluid p-form grid p-grid grid row"
          style={{ overflow: "hidden" }}
        >
          <div className="p-field col-xs-12 col-sm-12 col-md-12  col-lg-12">
            <label
              className={classNames({ "p-error": isFormFieldValid("name") })}
            >
              {" "}
              Name*
            </label>

            <InputText
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={classNames(
                { "p-invalid": isFormFieldValid("name") },
                ""
              )}
            />
            {getFormErrorMessage("name")}
          </div>
        </div>

        <div className="flex cus-buton">
          <Button
            label="Submit"
            loading={saveBtnLoading}
            className="p-button-rounded p-button-primary"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default CustForm;
