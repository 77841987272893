import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { Image } from "primereact/image";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../ManageSales.css";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const InventoryDetail = ({}) => {
  const location = useLocation();
  const pId = location.search;
  const id = pId.slice(1);

  const [inventoryDetail, setInventoryDetail] = useState([]);
  // use states

  // custom function ENDED
  useEffect(() => {
    // Make an API request to fetch company data
    Axios.get(`${process.env.REACT_APP_Url}/api/product/${id}`)
      .then((response) => {
        setInventoryDetail([response.data]);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, []);
  const icon = <i className="pi pi-check"></i>;
  const temp = (rowData) => {
    return (
      <Image
        src={process.env.REACT_APP_Url + "/" + rowData?.ProductImage}
        indicatorIcon={icon}
        alt="Image"
        preview
        width="50"
      />
    );
  };
  const supplier = (rowData) => {
    const sup = rowData?.supplier;
    let ss = sup?.map((s) => {
      return `${s?.supplierId?.supplierName} price is ${s?.supplierPrice} Rs`;
    });

    return <div style={{}}>{ss.join(" and ")}</div>;
  };

  return (
    <>
      <div className="card">
        <h3 className="mt-2">Product Detail </h3>
        <DataTable
          value={inventoryDetail}
          lazy
          // filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
        >
          <Column body={temp} header="Product Image" />
          <Column field="products_name" header="Product Name" />
          <Column field="local_name" header="Local Name" />
          <Column field="manufacturer" header="manufacturer" />

          <Column field="chemical_formulation" header="Formula" />

          <Column field="country_of_origin" header="Origin" />
          <Column field="SKU" header="SKU" />
          <Column field="packing" header="Packing" />
          <Column field="net_weight" header="Net Weight (g)" />
          <Column
            field="physical_attributes"
            header="Physical Attribute"
            // body={(rowData) => (
            //   <LongTextRenderer value={rowData.physical_attributes} />
            // )}
          />
          <Column
            field="Chemical_attributes"
            header="Chemical Attribute"
            // body={(rowData) => (
            //   <LongTextRenderer value={rowData?.Chemical_attributes} />
            // )}
          />

          <Column field="Unit_price" header="Unit Price (PKR)" />
          <Column field="chemical_formulation" header="Chemical formulation" />
          <Column field="Pack_price" header="Pack Price (PKR)" />
          {/* <Column field="productQuantity" header="Quantity" /> */}
          <Column
            field="Date"
            header="Date"
            body={(rowData) => moment(rowData?.Date).format("YYYY-MM-DD")}
          />

          <Column field="Industry" header="Industry" />
          <Column
            feild="file"
            header="Product Attachment"
            body={(rowData) => (
              <a
                className="w-4 mr-2 text-green-700"
                target="_blank"
                href={process.env.REACT_APP_Url + "/" + rowData?.file}
              >
                {rowData?.file ? "Open attachment" : "No file"}
              </a>
            )}
          />
          <Column field={supplier} header="Supplier " />
        </DataTable>
      </div>
    </>
  );
};

export default InventoryDetail;
