import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Axios from "axios";
import { MultiSelect } from "primereact/multiselect";
import * as Yup from "yup";
import { useState } from "react";
import classNames from "classnames";
import "./style.css";
export default function AddEditRolePermission({
  editData,
  permissionValue,
  rowData,
  selectedPermissions,
  setSelectedPermissions,
}) {
  const [selectPermission, setSelectPermission] = useState();
  const handleChange = (e, rowData) => {
    setSelectPermission(e.target.value);

    if (
      selectedPermissions.find((permission) => {
        return permission?.module === rowData?._id;
      })
    ) {
      setSelectedPermissions(
        selectedPermissions.map((permission) => {
          if (permission.module === rowData?._id) {
            return { module: rowData?._id, permissions: e.target.value };
          } else {
            return permission;
          }
        })
      );
    } else {
      setSelectedPermissions((pre) => {
        return [...pre, { module: rowData?._id, permissions: e.target.value }];
      });
    }
  };

  return (
    <>
      <div className="field-checkbox">
        <MultiSelect
          value={selectPermission}
          onChange={(e) => handleChange(e, rowData)}
          optionLabel="name"
          options={permissionValue}
          //   optionLabel="name" display="chip"
          id="permission"
          placeholder="Select  permission"
          name="permission"
          // className="p-multiselect-panel p-multiselect-items "
          axSelectedLabels={3}
        />
      </div>
    </>
  );
}
